import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import BaseLayout from '@app/components/layouts/BaseLayout';
import OrderLayout from '@app/components/orders/OrderLayout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const OrderPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.orders')}</PageTitle>
        <OrderLayout />
      </>
    </BaseLayout>
  );
};

export default OrderPage;
