import { getUser } from '@app/api/user.api';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { clearLocalStorageWhenLogout, getRole, readToken } from '@app/services/localStorage.service';
import { initialState, setUser } from '@app/store/slices/userSlice';
import { ReactElement, useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

const ProtectedRoute: React.FC<{ component?: ReactElement }> = ({ component }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  console.log({ location, searchParams });

  useEffect(() => {
    const token = readToken();
    const role = getRole();

    const fetchUserData = async () => {
      await getUser().then((data) => {
        const convertData = {
          uuid: data.userUuid,
          fullName: data.fullname,
          email: data.email,
          stores: data.stores,
          socials: data.socials,
          address: data.address,
          walletBalance: data.walletBalance,
          avatar: data.avatar,
        };

        dispatch(
          setUser({
            ...convertData,
            roles: role,
          }),
        );
      });
    };

    if (token && role) {
      fetchUserData();
    } else {
      clearLocalStorageWhenLogout();
      dispatch(setUser(initialState));
    }
  }, []);

  return <>{component || <Outlet />}</>;
};

export default ProtectedRoute;
