import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { TopUpPaymentCard } from '../TopUpPaymentCard/TopUpPaymentCard';
import { TopUpDataProps } from '../../interfaces/interfaces';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import * as S from './TopUpBalanceForm.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Input } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { InboxOutlined } from '@ant-design/icons';
import { readToken } from '@app/services/localStorage.service';
import { PaymentChannelModel } from '@app/api/topUp.api';

export const TopUpBalanceForm: React.FC<TopUpDataProps> = ({ cards, loading, onFinish }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  const [paymentChannel, setPaymentChanel] = useState<PaymentChannelModel[]>(cards);

  useEffect(() => {
    setPaymentChanel(cards);
  }, [cards]);

  const [channelIdx, setPaymentChannel] = useState<number>(0);
  form.setFieldValue('paymentChannel', paymentChannel[channelIdx]?.name.toLocaleUpperCase());
  const uploadProps = {
    name: 'image',
    multiple: false,
    action: `${process.env.REACT_APP_BASE_URL}/api/v1/upload/image`,
    headers: {
      Authorization: `Bearer ${readToken()}`,
    },
    maxCount: 1,
    onChange: (info: any) => {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        notificationController.success({ message: t('uploads.successUpload', { name: info.file.name }) });
      } else if (status === 'error') {
        notificationController.error({ message: t('uploads.failedUpload', { name: info.file.name }) });
      }
    },
  };

  return (
    <BaseForm form={form} name="topUpBalance" onFinish={onFinish} requiredMark="optional" validateTrigger="onBlur">
      <S.ContentWrapper>
        <S.BlockWrapper>
          {/* <S.Label>{t('topup.selectChannel')}</S.Label> */}

          <NFTCard>
            <BaseForm.Item name="paymentChannel" rules={[{ required: true }]}>
              <BaseSpace direction="vertical" size={10}>
                <BaseRadio.Group
                  value={channelIdx}
                  onChange={(event) => {
                    form.setFieldValue('paymentChannel', paymentChannel[event.target.value].name.toLocaleUpperCase());
                    setPaymentChannel(event.target.value);
                  }}
                >
                  {paymentChannel.map((card, idx) => {
                    const paymentCard: string = card.name.toUpperCase();

                    return (
                      <BaseRadio.Button key={paymentCard} value={idx}>
                        <TopUpPaymentCard img={card.iconUri} type={paymentCard} />
                      </BaseRadio.Button>
                    );
                  })}
                </BaseRadio.Group>
              </BaseSpace>
              <S.PaymentDescription
                dangerouslySetInnerHTML={{ __html: paymentChannel[channelIdx]?.description }}
              ></S.PaymentDescription>
            </BaseForm.Item>
          </NFTCard>
        </S.BlockWrapper>

        <S.BlockWrapper>
          <NFTCard>
            <S.Label> {t('topup.amount')}</S.Label>
            <BaseForm.Item name="amount" initialValue={200} rules={[{ required: true }]}>
              <InputNumber block type="number" addonBefore={<>USD</>} min={200} />
            </BaseForm.Item>
            <S.Label> {t('topup.transactionId')}</S.Label>
            <BaseForm.Item name="transactionId" rules={[{ required: true }]}>
              <Input />
            </BaseForm.Item>
            <S.Label> {t('topup.evidence')}</S.Label>
            <BaseForm.Item name="evidence" rules={[{ required: true }]}>
              <BaseUpload.Dragger {...uploadProps}>
                <S.DraggerIconWrapper>
                  <InboxOutlined />
                </S.DraggerIconWrapper>
                <S.DraggerTitle>{t('uploads.dragUpload')}</S.DraggerTitle>
                <S.DraggerDescription>{t('uploads.bulkUpload')}</S.DraggerDescription>
              </BaseUpload.Dragger>
            </BaseForm.Item>
          </NFTCard>
        </S.BlockWrapper>
      </S.ContentWrapper>

      <BaseForm.Item style={{ margin: 0 }}>
        <BaseButton type="primary" htmlType="submit" block disabled={loading}>
          {t('nft.topUpBalance')}
        </BaseButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
