import { TypeSelect, dataType, getTickets, paramsType } from '@app/api/ticket.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useMounted } from '@app/hooks/useMounted';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from '@app/api/table.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeOutlined } from '@ant-design/icons';
import { BaseAlert } from '@app/components/common/BaseAlert/BaseAlert';
import { Link } from 'react-router-dom';
import { TicketModal } from './TicketModal';
import { Wrapper } from './Ticket.styles';
import BaseLayout from '@app/components/layouts/BaseLayout';

type RowType = {
  uuid: string;
  createdDate: string;
} & dataType;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const TicketsPage: React.FC = () => {
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [valueSearch, setValueSearch] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [needLoad, setNeedLoad] = useState<boolean>(true);
  const [tableData, setTableData] = useState<{ data: RowType[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const columns: ColumnsType<RowType> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      render: (text: string, record: RowType) => {
        return <Link to={`detail-ticket/${record.uuid}`}>{text}</Link>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text: number) => {
        const content = TypeSelect.find((item) => item.value === text);
        return <span>{content?.label}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        switch (text) {
          case 'OPENING':
            return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Opening'} />;
          case 'CLOSED':
            return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Closed'} />;
          default:
            return (
              <BaseAlert
                style={{ width: 'fit-content', background: '#FFDE59' }}
                className={'statuss-in-product'}
                message={text.toLowerCase()}
              />
            );
        }
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (text: string) => {
        const content = new Date(text).toLocaleString();
        return <span>{content}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      align: 'center',
      render: (uuid: string) => {
        return (
          <Link to={`detail-ticket/${uuid}`}>
            <BaseSpace style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
              <EyeOutlined />
            </BaseSpace>
          </Link>
        );
      },
    },
  ];
  const fetch = useCallback(
    async (pagination: Pagination) => {
      let params: paramsType = {
        sort: 'createdDate,desc',
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || 10,
      };
      if (valueSearch) {
        params = {
          ...params,
          s: valueSearch,
        };
      }
      try {
        const res = await getTickets(params);
        if (isMounted.current) {
          setTableData((prev) => ({
            ...prev,
            loading: false,
            data: res.content.map((item: dataType, index: number) => {
              const newItem = {
                ...item,
                no: res.number * res.size + index + 1,
              };
              return newItem;
            }),
            pagination: {
              total: res.totalElements,
              current: res.number + 1,
              pageSize: params.size,
            },
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableData((tableData) => ({ ...tableData, loading: false }));
      }
    },
    [valueSearch, isMounted],
  );
  useEffect(() => {
    if (needLoad) {
      fetch(initialPagination);
      setNeedLoad(false);
    }
  }, [fetch, needLoad]);
  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };
  const handleModal = () => {
    setModalOpen((open) => !open);
  };

  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.tickets')}</PageTitle>

        <Wrapper id="category-card" padding="0" autoHeight={false}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1.25rem' }}>
            <NewsStyle.InputWrapper style={{ marginBottom: '0' }}>
              <NewsStyle.SearchIcon />
              <NewsStyle.Input
                placeholder={t('header.search')}
                value={valueSearch}
                onChange={(event) => {
                  setValueSearch(event.target.value);
                  setNeedLoad(true);
                }}
              />
            </NewsStyle.InputWrapper>
            <BaseButton type="primary" onClick={handleModal}>
              <PlusIcon style={{ height: '1.25rem' }} />
              <span style={{ textTransform: 'capitalize' }}>New ticket</span>
            </BaseButton>
          </div>

          <BaseTable
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            rowKey={(record) => record.uuid}
          />
        </Wrapper>

        <TicketModal isOpen={isModalOpen} onOpenChange={handleModal} onLoad={() => setNeedLoad(true)} />
      </>
    </BaseLayout>
  );
};

export default TicketsPage;
