import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Wrapper } from './History.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { TFormData } from '@app/api/history.api';
import { historyDataSelector, searchHistory } from '@app/store/slices/historySlice';
import { formatDate } from '@app/utils/utils';
import { Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SelectInfo } from 'rc-menu/lib/interface';
import BaseLayout from '@app/components/layouts/BaseLayout';

interface DataType {
  uuid: string;
  type: string;
  message: string;
  user: string;
  date: Date;
}

const initialFormData: TFormData = {
  text: '',
  fromDate: '',
  toDate: '',
  changeType: null,
  email: '',
  size: 20,
  page: 0,
};

const HistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [valueSearch, setValueSearch] = useState<string>('');

  const { data: historyData, loading: loadingTable } = useAppSelector(historyDataSelector);

  const tableData = useMemo(() => {
    return {
      data: historyData?.content?.map((item) => {
        return {
          uuid: item.uuid,
          type: item.changeType,
          message: item.source,
          user: item.email,
          date: item.createdDate,
        };
      }),
      pagination: {
        total: historyData.totalElements,
        current: historyData.number + 1,
        pageSize: historyData.size || 10,
      },
    };
  }, [historyData]);

  useEffect(() => {
    dispatch(searchHistory(initialFormData));
  }, [dispatch]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
    {
      title: 'User',
      dataIndex: 'user',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (data: Date) => {
        const content = formatDate(data, 'internal');
        return <span>{content}</span>;
      },
    },
  ];

  const handleTableChange = (pagination: Pagination) => {
    dispatch(
      searchHistory({
        ...initialFormData,
        page: pagination.current ? pagination.current - 1 : 0,
      }),
    );
  };

  const handleChangeType = (value: SelectInfo) => {
    console.log({ value });
  };

  const items: MenuProps['items'] = [
    {
      key: 'all',
      label: 'All',
    },
    {
      key: 'add',
      label: 'Add Money',
    },
    {
      key: 'subtract',
      label: 'Subtract Money',
    },
  ];

  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.history')}</PageTitle>

        <Wrapper>
          <BaseCard padding={'0'} style={{ backgroundColor: 'unset' }}>
            <div
              style={{
                padding: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', gap: '15px' }}>
                <div>Filter by:</div>

                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: ['all'],
                    onSelect: (item) => handleChangeType(item),
                  }}
                  trigger={['click', 'hover']}
                >
                  <div>
                    <Space>
                      Type
                      <DownOutlined />
                    </Space>
                  </div>
                </Dropdown>
              </div>

              <NewsStyle.InputWrapper style={{ marginBottom: '0', width: '280px' }}>
                <NewsStyle.SearchIcon />
                <NewsStyle.Input
                  placeholder={t('header.search')}
                  value={valueSearch}
                  onChange={(event) => {
                    setValueSearch(event.target.value);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      dispatch(
                        searchHistory({
                          ...initialFormData,
                          page: 0,
                          text: valueSearch,
                        }),
                      );
                    }
                  }}
                />
              </NewsStyle.InputWrapper>
            </div>

            <BaseTable
              columns={columns}
              dataSource={tableData.data}
              pagination={tableData.pagination}
              loading={loadingTable}
              onChange={handleTableChange}
              scroll={{ x: 1000 }}
              rowKey={(record) => record.uuid}
            />
          </BaseCard>
        </Wrapper>
      </>
    </BaseLayout>
  );
};

export default HistoryPage;
