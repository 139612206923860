import { httpApi } from '@app/api/http.api';
import { RefreshTokenState } from '@app/constants/http';
import {
  clearLocalStorageWhenLogout,
  getRefreshToken,
  persistToken,
  readToken,
  setRefreshToken,
} from '@app/services/localStorage.service';
import { IRefreshTokenState } from '@app/types/common';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';

export const cancelRefreshToken = () => {
  clearLocalStorageWhenLogout();
  //   Utils.saveRedirectUrl();
  window.location.href = `/auth/login?callBack=${window.location.pathname}`;
};

// Cannot use hook here because this function is used out of react component
export default async function useRefreshToken(callback: (value: IRefreshTokenState) => void) {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    cancelRefreshToken();
  } else {
    callback(RefreshTokenState.CALLING);

    try {
      const response = await httpApi.get('/api/v1/auth/refresh-token');

      callback(RefreshTokenState.STOP);
      persistToken(response?.data?.accessToken || '');
      setRefreshToken(response?.data?.refreshToken || '');
      httpApi.defaults.headers.common['Authorization'] = response?.data?.accessToken || '';

      window.location.reload();
    } catch (error) {
      callback(RefreshTokenState.STOP);
      cancelRefreshToken();
    }
  }
}

export const useAuthLoader = async ({ request }: LoaderFunctionArgs) => {
  const isLoggedIn = readToken();
  const url = new URL(request.url);

  console.log({ url });

  // Check if path is "/auth" then redirect to "/auth/login"
  if ((url.pathname === '/auth' || url.pathname === '/auth/') && !isLoggedIn) {
    return redirect('/auth/login');
  }

  if (isLoggedIn) {
    return redirect('/');
  }
  return null;
};
