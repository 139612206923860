import React, { useEffect, useState } from 'react';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { TopUpFormData } from '../../interfaces/interfaces';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { TopUpBalanceForm } from '../TopUpBalanceForm/TopUpBalanceForm';
import { getPaymentChannels, PaymentChannelModel } from '@app/api/topUp.api';
import { useTranslation } from 'react-i18next';

interface TopUpBalanceModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onFinish: (data: TopUpFormData) => void;
  loading: boolean;
}

export const TopUpBalanceModal: React.FC<TopUpBalanceModalProps> = ({ isOpen, onCancel, onFinish, loading }) => {
  const { t } = useTranslation();
  const [cards, setCards] = useState<PaymentChannelModel[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    getPaymentChannels('en')
      .then((res) => setCards(res))
      .finally(() => setLoadingData(false));
  }, [isOpen, setLoadingData]);

  return (
    <BaseModal
      title={t('topup.selectChannel')}
      style={{ top: 20 }}
      width={500}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <BaseSpin spinning={loadingData || loading}>
        <TopUpBalanceForm cards={cards} loading={loading} onFinish={onFinish} />
      </BaseSpin>
    </BaseModal>
  );
};
