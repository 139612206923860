import { UserInfoModel, UpdateUserInfoRequest, ChangeUserPasswordRequest } from '@app/domain/UserModel';
import { httpApi } from './http.api';

export const getUser = async () =>
  httpApi.get<UserInfoModel>('/api/v1/user/info').then(({ data }) => {
    return data;
  });

export const updateUserInfo = (request: UpdateUserInfoRequest) => {
  const convertData = {
    ...request,
    fullname: request.fullName,
  };

  return httpApi.put<undefined | any>('/api/v1/user/info', { ...convertData }).then(({ data }) => {
    return data;
  });
};

export const changeUserPassword = (request: ChangeUserPasswordRequest) =>
  httpApi.put<undefined | any>('/api/v1/user/password', { ...request }).then(({ data }) => data);

export interface OverviewUser {
  orders: number;
  sales: number;
  customers: number;
  refunds: number;
  pending: number;
  confirmed: number;
  inTroduction: number;
  shipped: number;
  accepted: number;
  inTransit: number;
  delivered: number;
  cancel: number;
  productionTime: number;
  shippingTime: number;
  //
  balance: number;
  unsolvedTickets: number;
  tobePaidAmount: number;
}

export const getOverviewUser = () => httpApi.get<OverviewUser>('/api/v1/analytics/overview').then(({ data }) => data);
