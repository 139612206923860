import React, { useCallback, useEffect, useState } from 'react';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseModal } from '../common/BaseModal/BaseModal';
import { BaseSpin } from '../common/BaseSpin/BaseSpin';
import {
  AvatarPrevireContainer,
  ChooseColorContainer,
  ColorPickerContainer,
  MockupPositionContainer,
  ModalContainer,
  ModalFooter,
  ModalMain,
  ProductSkuSltContainer,
  UnitPriceContainer,
} from './conmon.styles';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';
import { useTranslation } from 'react-i18next';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import TextArea from '../common/BaseTextArea/BaseTextArea';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { dataType, newCategory, updateCategory } from '@app/api/category.api';
import { notificationController } from '@app/controllers/notificationController';
import { HexColorPicker } from 'react-colorful';
import { ColorType, UnitPriceType, positionsType, shippingRatesType } from '@app/api/product.api';
import { BaseTable } from '../common/BaseTable/BaseTable';
import { v4 as uuidv4 } from 'uuid';
import { BaseSpace } from '../common/BaseSpace/BaseSpace';
import { BaseSelect, Option } from '../common/selects/BaseSelect/BaseSelect';
import { MinusIcon, PlusIcon } from '../apps/newsFeed/NewsFilter/NewsFilter.styles';
import { Form, Input } from 'antd';
import { BaseUpload } from '../common/BaseUpload/BaseUpload';
import { UploadOutlined } from '@ant-design/icons';
import { ReactComponent as XMarkIcon } from '@app/assets/icons/x-mark.svg';
import { Link } from 'react-router-dom';
import { BasePopover } from '../common/BasePopover/BasePopover';
import { GetColorName } from 'hex-color-to-color-name';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
import { ColumnsType } from 'antd/es/table';

export type CategoryData = {
  uuid: string;
} & dataType;

interface CategoryModalProps {
  data: CategoryData;
  type: string;
  isOpen: boolean;
  onOpenChange: () => void;
  onLoad: () => void;
}

export const CategoryModal: React.FC<CategoryModalProps> = ({ data, type, isOpen, onOpenChange, onLoad }) => {
  const [loading, setLoading] = useState(false);
  //   const { t } = useTranslation();
  const handleSubmit = (values: dataType) => {
    setLoading(true);
    switch (type) {
      case 'New':
        newCategory(values)
          .then(() => {
            onLoad();
            onOpenChange();
            notificationController.success({ message: 'Create new category success!' });
          })
          .catch((err) => {
            console.log('create new cate err', err);
            notificationController.error({ message: err.message });
          })
          .finally(() => setLoading(false));
        break;
      case 'Edit':
        updateCategory(data.uuid, values)
          .then(() => {
            onLoad();
            onOpenChange();
            notificationController.success({ message: 'Update category success!' });
          })
          .catch((err) => {
            console.log('Update cate err', err);
            notificationController.error({ message: err.message });
          })
          .finally(() => setLoading(false));
        break;
    }
  };
  return (
    <BaseModal
      title={`${type} Category`}
      width={500}
      open={isOpen}
      onCancel={onOpenChange}
      footer={null}
      destroyOnClose
    >
      <BaseSpin spinning={loading}>
        <ModalContainer>
          <ModalMain>
            <BaseForm id={'category-form'} layout="vertical" onFinish={handleSubmit} initialValues={data}>
              <BaseRow gutter={[20, 24]}>
                <BaseCol span={12}>
                  <BaseForm.Item name="name" label={'Name'} rules={[{ required: true, message: 'Name is required' }]}>
                    <BaseInput />
                  </BaseForm.Item>
                </BaseCol>
                <BaseCol span={12}>
                  <BaseForm.Item
                    name="preCode"
                    label={'Pre-code'}
                    rules={[{ required: true, message: 'Pre-code is required' }]}
                  >
                    <BaseInput />
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
              <BaseForm.Item name="description" label={'Description'}>
                <TextArea />
              </BaseForm.Item>
            </BaseForm>
          </ModalMain>
          <ModalFooter>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <BaseButton type="ghost" onClick={onOpenChange}>
                Cancel
              </BaseButton>
              <BaseButton type="primary" htmlType="submit" form={'category-form'}>
                OK
              </BaseButton>
            </div>
          </ModalFooter>
        </ModalContainer>
      </BaseSpin>
    </BaseModal>
  );
};

interface ProductSkuProps {
  preCode: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const ProductSkuSelect: React.FC<ProductSkuProps> = ({ preCode, value, onChange }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <ProductSkuSltContainer>
      <div className="preCode">
        <span>{preCode}</span>
      </div>
      <Input value={value} onChange={handleInputChange} />
    </ProductSkuSltContainer>
  );
};

interface ColorPickerProps {
  values?: ColorType[];
  onChangeColor: (values: ColorType[]) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ onChangeColor, values }) => {
  const [color, setColor] = useState('#1677FF');
  const [colorName, setColorName] = useState('');
  const [sColor, setSColor] = useState<boolean>(false);
  const [sName, setSName] = useState<boolean>(false);
  const initColor: ColorType = { hex: '#', name: '' };
  const [colorValues, setColorValues] = useState<ColorType[]>(values || []);
  const [form] = Form.useForm();
  extend([namesPlugin]);

  useEffect(() => {
    if (values) {
      setColorValues(values);
    }
  }, [values]);

  const handleFinish = (values: ColorType) => {
    const isHasColor = colorValues.find((item) => item.hex === values.hex);
    if (isHasColor) {
      return;
    }
    if (values.name === '') {
      values.name = colorName;
    }
    setColorValues((colorValues) => [...colorValues, values]);
    onChangeColor([...colorValues, values]);
    form.resetFields();
    setSColor(false);
    setSName(false);
  };
  const handleRemoveColor = (rcolor: string) => {
    const newColors = colorValues.filter((item) => item.hex !== rcolor);
    setColorValues(newColors);
    onChangeColor(newColors);
  };
  const handleSuggestionName = (hex: string) => {
    if (hex && colord(hex).isValid()) {
      const name = GetColorName(hex.replace('#', ''));
      setColorName(name);
      setSName(true);
    } else {
      setSName(false);
    }
  };
  const handleSetColorPicker = (hex: string) => {
    form.setFieldsValue({ hex });
    handleSuggestionName(hex);
  };
  // const handleSuggestionColor = (name: string) => {
  //   if (name) {
  //     const hexColor = colord(name).toHex();
  //     setSColor(true);
  //     setColor(hexColor);
  //   } else {
  //     setSColor(false);
  //   }
  // };
  const handleSetName = (name: string) => {
    form.setFieldsValue({ name });
    setSName(false);
  };
  const handleSetColor = (hex: string) => {
    form.setFieldsValue({ hex });
    setSColor(false);
  };
  return (
    <ColorPickerContainer>
      <BasePopover
        content={
          <ChooseColorContainer>
            <HexColorPicker className="w-100" color={form.getFieldValue('hex')} onChange={handleSetColorPicker} />
            <div className={`color-text`}>
              <BaseForm
                form={form}
                id={'color-form'}
                onFinish={handleFinish}
                layout="vertical"
                initialValues={initColor}
              >
                <BaseForm.Item
                  name="hex"
                  style={{ margin: '0', width: '100%' }}
                  rules={[
                    { required: true, message: 'Color is required' },
                    () => ({
                      validator(_, value) {
                        if (colord(value).isValid()) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Color is not valid');
                      },
                    }),
                  ]}
                >
                  <BaseInput
                    className="color-picker"
                    onChange={(event) => handleSuggestionName(event.target.value)}
                    addonBefore={<span>{'Hex'}</span>}
                    addonAfter={sColor ? <span onClick={() => handleSetColor(color)}>{color}</span> : <></>}
                  />
                </BaseForm.Item>
                <BaseForm.Item
                  name="name"
                  style={{ margin: '0', width: '100%' }}
                  rules={[{ required: true, message: 'Color name is required' }]}
                >
                  <BaseInput
                    className="color-picker"
                    // onChange={(event) => handleSuggestionColor(event.target.value)}
                    addonBefore={<span>{'Name'}</span>}
                    addonAfter={
                      sName ? (
                        <span
                          onClick={() => {
                            handleSetName(colorName);
                          }}
                        >
                          {colorName}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                  />
                </BaseForm.Item>
                <BaseButton
                  type="primary"
                  style={{ padding: '0', height: 'fit-content' }}
                  htmlType="submit"
                  form={'color-form'}
                >
                  Add
                </BaseButton>
              </BaseForm>
            </div>
          </ChooseColorContainer>
        }
        trigger="click"
      >
        <span className="color-picker">
          <span className="color-preview" style={{ '--color-picker': '#1677FF' } as React.CSSProperties}></span>
          <span>Choose colors</span>
        </span>
      </BasePopover>

      <div className={`selected-color`}>
        {colorValues.map((item: ColorType) => (
          <span className="list-container" key={item.hex}>
            <div className="color-preview" style={{ '--color-picker': item.hex } as React.CSSProperties}></div>
            <span title={item.name} className="color-name">
              {item.name}
            </span>
            <svg
              onClick={() => handleRemoveColor(item.hex)}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M18 6L6 18"
                fill="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M6 6L18 18"
                fill="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </span>
        ))}
      </div>
    </ColorPickerContainer>
  );
};

interface ShippingRatesProps {
  initData?: shippingRatesType[];
  getData: (newData: shippingRatesType[]) => void;
}
interface ShippingRatesRow {
  [key: string]: shippingRatesType;
}
export interface selectType {
  label: string;
  value: string;
}
const ShippingRatesOperator: selectType[] = [
  { label: 'Less', value: 'LESS' },
  { label: 'Less or Equal', value: 'LESS_OR_EQUAL' },
  { label: 'Equal', value: 'EQUAL' },
  { label: 'Greater or Equal', value: 'GREATER_OR_EQUAL' },
  { label: 'Greater', value: 'GREATER' },
];

export const ShippingRatesTable: React.FC<ShippingRatesProps> = ({ getData, initData }) => {
  const initRow: ShippingRatesRow = {
    [uuidv4()]: {
      operator: 'EQUAL',
      quantity: 0,
      surcharge: 0,
    },
  };
  const [dataRow, setDataRow] = useState<ShippingRatesRow[]>([]);
  const handleAddRow = () => {
    setDataRow((dataRow) => [...dataRow, initRow]);
  };
  const sendDataToParent = useCallback(() => {
    const newData: shippingRatesType[] = dataRow.map((item: ShippingRatesRow) => {
      const key = Object.keys(item)[0];
      return item[key] as shippingRatesType;
    });
    getData(newData);
  }, [dataRow, getData]);
  useEffect(() => {
    sendDataToParent();
  }, [dataRow, sendDataToParent]);
  useEffect(() => {
    if (initData) {
      setDataRow(initData.map((item) => ({ [uuidv4()]: item })));
    }
  }, [initData]);

  const handleChangeValue = (key: string, subkey: string, value: string) => {
    const newData = dataRow.map((item: ShippingRatesRow) => {
      if (Object.keys(item)[0] === key) {
        const newItem: ShippingRatesRow = { ...item, [key]: { ...(item[key] as shippingRatesType), [subkey]: value } };
        return newItem;
      }
      return item;
    });

    setDataRow(newData);
  };

  const columns: ColumnsType<ShippingRatesRow> = [
    {
      title: 'Operator',
      dataIndex: 'operator',
      render: (text: string, record: ShippingRatesRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace>
            <BaseSelect
              id={key}
              onChange={(value) => {
                handleChangeValue(key, 'operator', value as string);
              }}
              value={record[key].operator}
              width={200}
              style={{ width: '12.25rem' }}
              getPopupContainer={() => document.body}
            >
              {ShippingRatesOperator.map((item: selectType) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text: string, record: ShippingRatesRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace>
            <BaseInput
              onChange={(event) => {
                const value = event.target.value;
                if (!(value < '0') || value == '') {
                  handleChangeValue(key, 'quantity', value);
                }
              }}
              value={record[key].quantity}
              type="number"
              placeholder={'Quantity'}
            />
          </BaseSpace>
        );
      },
    },
    {
      title: 'Surcharge',
      dataIndex: 'surcharge',
      render: (text: string, record: ShippingRatesRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace>
            <BaseInput
              onChange={(event) => {
                const value = event.target.value;
                if (!(value < '0') || value == '') {
                  handleChangeValue(key, 'surcharge', value);
                }
              }}
              value={record[key].surcharge}
              type="number"
              placeholder={'Surcharge'}
            />
          </BaseSpace>
        );
      },
    },
    {
      title: (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PlusIcon onClick={handleAddRow} style={{ margin: '0' }} />
        </div>
      ),
      dataIndex: 'actions',
      width: '8%',
      render: (text: string, record: ShippingRatesRow) => {
        const key = Object.keys(record)[0];
        const removeRow = (key: string) => {
          const newData = dataRow.filter((item) => Object.keys(item)[0] !== key);
          setDataRow(newData);
        };
        return (
          <BaseSpace
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <MinusIcon onClick={() => removeRow(key)} style={{ margin: '0', color: 'var(--error-color)' }} />
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <div>
      <BaseTable
        bordered
        dataSource={dataRow}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 800 }}
        rowKey={(record) => Object.keys(record)[0]}
      />
    </div>
  );
};

export const PositionsTypeSlt: selectType[] = [
  { label: 'Embroidery', value: 'EMBROIDERY' },
  { label: 'Printing', value: 'PRINTING' },
  { label: 'Puff Printing', value: 'PUFF_PRINTING' },
  { label: 'Applique', value: 'APPLIQUE' },
  { label: 'Glitter Applique', value: 'GLITTER_APPLIQUE' },
  { label: 'Glitter Printing', value: 'GLITTER_PRINTING' },
  { label: 'Leather Patch', value: 'LEATHER_PATCH' },
  { label: 'Fabric Patch', value: 'FABRIC_PATCH' },
  { label: 'Only Right Sleeve', value: 'ONLY_RIGHT_SLEEVE' },
  { label: 'Only Left Sleeve', value: 'ONLY_LEFT_SLEEVE' },
  { label: 'Only Neck', value: 'ONLY_NECK' },
  { label: 'Bow Applique', value: 'BOW_APPLIQUE' },
];
export const PositionsPositionSlt: selectType[] = [
  {
    label: 'Center Chest',
    value: 'CENTER_CHEST',
  },
  {
    label: 'Left Chest',
    value: 'LEFT_CHEST',
  },
  {
    label: 'Right Chest',
    value: 'RIGHT_CHEST',
  },
  {
    label: 'Left Sleeve',
    value: 'LEFT_SLEEVE',
  },
  {
    label: 'Right Sleeve',
    value: 'RIGHT_SLEEVE',
  },
  {
    label: 'Neck',
    value: 'NECK',
  },
  {
    label: 'Hat Front',
    value: 'HAT_FRONT',
  },
  {
    label: 'Hat Back',
    value: 'HAT_BACK',
  },
  {
    label: 'Hat Side',
    value: 'HAT_SIDE',
  },
  {
    label: 'Back',
    value: 'BACK',
  },
  {
    label: 'Arm',
    value: 'ARM',
  },
  {
    label: 'Right Arm',
    value: 'RIGHT_ARM',
  },
  {
    label: 'Left Arm',
    value: 'LEFT_ARM',
  },
  {
    label: 'Left Side',
    value: 'LEFT_SIDE',
  },
  {
    label: 'Right Side',
    value: 'RIGHT_SIDE',
  },
  {
    label: 'Neck/Back',
    value: 'NECK_BACK',
  },
];

const MockupPstPrevirew: React.FC<{ mockup: string; onChange: () => void }> = ({ mockup, onChange }) => {
  return (
    <MockupPositionContainer title={mockup}>
      <Link to={mockup} className="mockup-link">
        {mockup}
      </Link>
      <span className="mockup-icon" onClick={onChange}>
        <XMarkIcon />
      </span>
    </MockupPositionContainer>
  );
};

interface PositionsRow {
  [key: string]: positionsType;
}
interface PositionsProps {
  initData?: positionsType[];
  getData: (newData: positionsType[]) => void;
}
export const PositionsTable: React.FC<PositionsProps> = ({ getData, initData }) => {
  const initRow: PositionsRow = {
    [uuidv4()]: {
      position: 'CENTER_CHEST',
      type: 'PRINTING',
      surcharge: 0,
      mockup: [],
    },
  };
  const { t } = useTranslation();
  const [dataRow, setDataRow] = useState<PositionsRow[]>([]);
  const handleAddRow = () => {
    setDataRow((dataRow) => [...dataRow, initRow]);
  };
  const sendDataToParent = useCallback(() => {
    const newData: positionsType[] = dataRow.map((item: PositionsRow) => {
      const key = Object.keys(item)[0];
      return item[key] as positionsType;
    });
    getData(newData);
  }, [dataRow, getData]);

  useEffect(() => {
    sendDataToParent();
  }, [sendDataToParent]);

  useEffect(() => {
    if (initData) {
      setDataRow(initData.map((item) => ({ [uuidv4()]: item })));
    }
  }, [initData]);

  const handleChangeValue = (key: string, subkey: string, value: string | File[] | null) => {
    const newData = dataRow.map((item: PositionsRow) => {
      if (Object.keys(item)[0] === key) {
        const newItem: PositionsRow = { ...item, [key]: { ...(item[key] as positionsType), [subkey]: value } };
        return newItem;
      }
      return item;
    });

    setDataRow(newData);
  };
  const uploadProps = {
    name: 'file',
    accept: 'image/*',
    mutiple: false,
    beforeUpload: () => {
      return false;
    },
  };

  const columns = [
    {
      title: 'Position',
      dataIndex: 'position',
      render: (text: string, record: PositionsRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace>
            <BaseSelect
              id={key}
              onChange={(value) => {
                handleChangeValue(key, 'position', value as string);
              }}
              value={record[key].position}
              width={200}
              style={{ minWidth: '6.25rem' }}
              getPopupContainer={() => document.body}
            >
              {PositionsPositionSlt.map((item: selectType) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text: string, record: PositionsRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace>
            <BaseSelect
              id={key}
              onChange={(value) => {
                handleChangeValue(key, 'type', value as string);
              }}
              value={record[key].type}
              style={{ minWidth: '10rem' }}
              getPopupContainer={() => document.body}
            >
              {PositionsTypeSlt.map((item: selectType) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Surcharge',
      dataIndex: 'surcharge',
      render: (text: string, record: PositionsRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace style={{ width: '8rem' }}>
            <BaseInput
              onChange={(event) => {
                const value = event.target.value;
                if (!(value < '0') || value == '') {
                  handleChangeValue(key, 'surcharge', value);
                }
              }}
              value={record[key].surcharge}
              type="number"
              placeholder={'Surcharge'}
            />
          </BaseSpace>
        );
      },
    },
    {
      title: 'Mockup',
      dataIndex: 'mockup',
      render: (text: string, record: PositionsRow) => {
        const key = Object.keys(record)[0];
        const isString =
          (typeof record[key].mockup === 'string' || record[key].mockup instanceof String) &&
          record[key].mockup.length > 0;
        return (
          <BaseSpace>
            {isString ? (
              <MockupPstPrevirew
                mockup={record[key].mockup as string}
                onChange={() => handleChangeValue(key, 'mockup', '')}
              />
            ) : (
              <BaseUpload
                onChange={(info: any) => {
                  handleChangeValue(key, 'mockup', info);
                }}
                {...uploadProps}
                maxCount={1}
              >
                <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
              </BaseUpload>
            )}
          </BaseSpace>
        );
      },
    },
    {
      title: (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PlusIcon onClick={handleAddRow} style={{ margin: '0' }} />
        </div>
      ),
      dataIndex: 'actions',
      width: '8%',
      render: (text: string, record: PositionsRow) => {
        const key = Object.keys(record)[0];
        const removeRow = (key: string) => {
          const newData = dataRow.filter((item) => Object.keys(item)[0] !== key);
          setDataRow(newData);
        };
        return (
          <BaseSpace
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <MinusIcon onClick={() => removeRow(key)} style={{ margin: '0', color: 'var(--error-color)' }} />
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <div>
      <BaseTable
        bordered
        dataSource={dataRow}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 800 }}
        rowKey={(record) => Object.keys(record)[0]}
      />
    </div>
  );
};

export const AvatarPrevirew: React.FC<{ avatar: string; onChange: () => void }> = ({ avatar, onChange }) => {
  return (
    <div>
      <AvatarPrevireContainer>
        <img src={avatar} alt={avatar} className="image" title={avatar} />
        <span className="delete-icon" onClick={onChange}>
          <XMarkIcon />
        </span>
      </AvatarPrevireContainer>
    </div>
  );
};

interface ShippingRatesDetailProps {
  initData: shippingRatesType[];
}
export const ShippingRatesDetailTable: React.FC<ShippingRatesDetailProps> = ({ initData }) => {
  const columns = [
    {
      title: 'Operator',
      dataIndex: 'operator',
      render: (text: string) => {
        const operator = ShippingRatesOperator.find((item) => item.value === text);
        return (
          <BaseSpace>
            <span>{operator?.label}</span>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text: string) => {
        return (
          <BaseSpace>
            <span>{text}</span>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Shipping Fees',
      dataIndex: 'surcharge',
      render: (text: string) => {
        return (
          <BaseSpace>
            <span>${text}</span>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <div>
      <BaseTable
        bordered
        dataSource={initData}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 800 }}
        rowKey={(record) => record.uuid}
      />
    </div>
  );
};

interface PositionsDetailProps {
  initData: positionsType[];
}
export const PositionsDetailTable: React.FC<PositionsDetailProps> = ({ initData }) => {
  const columns = [
    {
      title: 'Position',
      dataIndex: 'position',
      render: (text: string) => {
        const position = PositionsPositionSlt.find((item) => item.value === text);
        return (
          <BaseSpace>
            <span>{position?.label}</span>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text: string) => {
        return (
          <BaseSpace>
            <span>{text}</span>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Surcharge',
      dataIndex: 'surcharge',
      render: (text: string) => {
        return (
          <BaseSpace>
            <span>${text}</span>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Mockup',
      dataIndex: 'mockup',
      render: (text: string) => {
        return (
          <BaseSpace style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
            {text && (
              <Link
                to={text}
                title={text}
                style={
                  {
                    display: '-webkit-box',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    padding: '0.25rem 0.5rem',
                    backgroundColor: 'rgb(249 250 251)',
                    border: '1px solid rgb(219, 219, 219)',
                    borderRadius: '0.25rem',
                  } as React.CSSProperties
                }
              >
                {text}
              </Link>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <div>
      <BaseTable
        bordered
        dataSource={initData}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 800 }}
        rowKey={(record) => record.uuid}
      />
    </div>
  );
};

interface UnitPriceProps {
  initData?: UnitPriceType[];
  getData: (newData: UnitPriceType[]) => void;
}
interface UnitPriceRow {
  [key: string]: UnitPriceType;
}

const SizeSelection: selectType[] = [
  { value: 'OS', label: 'OS' },
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: '2XL', label: '2XL' },
  { value: '3XL', label: '3XL' },
  { value: '4XL', label: '4XL' },
  { value: '5XL', label: '5XL' },
];

export const UnitPriceTable: React.FC<UnitPriceProps> = ({ getData, initData }) => {
  const [dataRow, setDataRow] = useState<UnitPriceRow[]>([]);
  const handleAddRow = () => {
    setDataRow((dataRow) => {
      const existingSizes = dataRow.map((row) => Object.values(row)[0].size);
      const newSize = SizeSelection.find((size) => !existingSizes.includes(size.value));
      if (newSize) {
        const newRow: UnitPriceRow = { [uuidv4()]: { unitPrice: 0, size: newSize.value } };
        return [...dataRow, newRow];
      }
      return dataRow;
    });
  };
  const sendDataToParent = useCallback(() => {
    const newData: UnitPriceType[] = dataRow.map((item: UnitPriceRow) => {
      const key = Object.keys(item)[0];
      return item[key] as UnitPriceType;
    });
    getData(newData);
  }, [dataRow, getData]);
  useEffect(() => {
    sendDataToParent();
  }, [dataRow, sendDataToParent]);
  useEffect(() => {
    if (initData) {
      setDataRow(initData.map((item) => ({ [uuidv4()]: item })));
    }
  }, [initData]);

  const handleChangeValue = (key: string, subkey: string, value: string | number) => {
    const newData = dataRow.map((item: UnitPriceRow) => {
      if (Object.keys(item)[0] === key) {
        const newItem: UnitPriceRow = { ...item, [key]: { ...(item[key] as UnitPriceType), [subkey]: value } };
        return newItem;
      }
      return item;
    });

    setDataRow(newData);
  };

  const columns: ColumnsType<UnitPriceRow> = [
    {
      title: 'Size',
      width: '35%',
      dataIndex: 'size',
      render: (text: string, record: UnitPriceRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace className="select-size">
            <BaseSelect
              id={key}
              onChange={(value) => {
                handleChangeValue(key, 'size', value as string);
              }}
              value={record[key].size}
              width={200}
              getPopupContainer={() => document.body}
            >
              {SizeSelection.map((item: selectType) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </BaseSelect>
          </BaseSpace>
        );
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      render: (text: string, record: UnitPriceRow) => {
        const key = Object.keys(record)[0];
        return (
          <BaseSpace>
            <BaseInput
              onChange={(event) => {
                const value = event.target.value;
                if (!(value < '0') || value == '') {
                  handleChangeValue(key, 'unitPrice', parseFloat(value));
                }
              }}
              value={record[key].unitPrice}
              type="number"
              placeholder={'Unit Price'}
            />
          </BaseSpace>
        );
      },
    },
    {
      title: (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PlusIcon onClick={handleAddRow} style={{ margin: '0' }} />
        </div>
      ),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: UnitPriceRow) => {
        const key = Object.keys(record)[0];
        const removeRow = (key: string) => {
          const newData = dataRow.filter((item) => Object.keys(item)[0] !== key);
          setDataRow(newData);
        };
        return (
          <BaseSpace
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <MinusIcon onClick={() => removeRow(key)} style={{ margin: '0', color: 'var(--error-color)' }} />
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <UnitPriceContainer>
      <BaseTable
        bordered
        dataSource={dataRow}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 200 }}
        rowKey={(record) => Object.keys(record)[0]}
      />
    </UnitPriceContainer>
  );
};
